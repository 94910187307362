import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store.js'
import axiosHelper from "@/util/axiosHelper.js";
import axiosJsonHelper from "@/util/axiosJsonHelper.js";
import axiosFileHelper from "@/util/axiosFileHelper.js";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.config.globalProperties.$axios = axiosHelper
app.config.globalProperties.$axiosJson = axiosJsonHelper
app.config.globalProperties.$axiosFile = axiosFileHelper
app.use(VueSweetalert2);
app.use(router).use(store).mount('#app')
