<template>
		<!-- Page Title (Shop Alt)-->
		<!-- used for shop templates with filters on top-->
		 <router-view></router-view>
</template>


<script>

export default {
  name: 'App',
   components: {
   }, 
}


</script>